import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "token-types"
    }}>{`Token Types`}</h1>
    <p>{`Dragnet provides you the ability to create seven (7) different types of tokens.`}</p>
    <ol>
      <li parentName="ol">{`Adobe PDF`}</li>
      <li parentName="ol">{`Word Documents`}</li>
      <li parentName="ol">{`Pixel`}</li>
      <li parentName="ol">{`Email`}</li>
      <li parentName="ol">{`Link`}</li>
      <li parentName="ol">{`Windows Folder`}</li>
      <li parentName="ol">{`DNS`}</li>
    </ol>
    <h3 {...{
      "id": "two-underlying-technologies"
    }}>{`Two Underlying Technologies`}</h3>
    <p>{`Dragnet tokens make use of two primary technologies (1) HTTP requests and (2) DNS queries. Windows Folders and DNS tokens make use of DNS queries.  All other tokens use DNS requests.`}</p>
    <p>{`There are pros and cons to each token type, and depending on the use case, you might find it helpful to use them in tandem.`}</p>
    <h4 {...{
      "id": "http-request-based-tokens"
    }}>{`HTTP Request based Tokens`}</h4>
    <p>{`HTTP tokens use basic HTTP requests as the alarm. As a result, the information the token generates is the same as is in a basic HTTP request.  Information such as the IP address of the machine triggering the token, the user agent, and the time of the event.  With the IP address, Dragnet attempts to geo-locate the source of the event, which you may find helpful in assessing the risk of the alert.  Please note that IP geolocation is imprecise. Precision may be compromised by VPNs, proxies, the Tor network, etc.`}</p>
    <p>{`The underlying technology of the HTTP tokens allows them to function instantaneously.`}</p>
    <p>{`ADD BIT ABOUT LINKS VS IMAGES`}</p>
    <h4 {...{
      "id": "dns-query-based-tokens"
    }}>{`DNS Query based Tokens`}</h4>
    <p>{`DNS tokens use DNS queries to trigger the alarm. `}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`What is a DNS Query?`}</strong></p>
      <p parentName="blockquote">{`Before your computer can connect to a website, such as yahoo.com, it needs to know the IP address of the server with which it will communicate. A DNS query is a request from your computer asking a DNS server that lives somewhere on the internet what the IP address is for a particular domain.  The server your computer talks to is called a DNS Resolver. If the DNS Resolver does not know the IP address, it queries a Root DNS server, a Top Level Domain server, and eventually, the Nameserver.`}</p>
    </blockquote>
    <p>{`DNS tokens query a unique subdomain, prompting the DNS network to query our nameserver. We ingest the query and process it as the alert.  Please note that the IP Address Dragnet sees in a DNS based security event is the IP address of the DNS Resolver server, rather than the IP address of the machine that activated the token. As a result, the IP geolocation is more imprecise than HTTP request based tokens. Generally, the geolocation of DNS based tokens will be within a 250 mile radius of the actual location, but can occasionally create results that appear to be in a different country or continent.`}</p>
    <p>{`What the DNS tokens lose in the form of geolocation precision, they make up for in the form of added network data included in the alert.  When a Windows Folder token is tripped, it automatically captures the Windows User Id and the Windows network domain. Knowing the Windows User Id that has been compromised during a breach is incredibly valuable information, because security administrators can simply disable the account and change the password.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      